// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-busqueda-tsx": () => import("./../../../src/pages/busqueda.tsx" /* webpackChunkName: "component---src-pages-busqueda-tsx" */),
  "component---src-pages-cerrajeria-apertura-de-vehiculos-tsx": () => import("./../../../src/pages/cerrajeria/apertura-de-vehiculos.tsx" /* webpackChunkName: "component---src-pages-cerrajeria-apertura-de-vehiculos-tsx" */),
  "component---src-pages-cerrajeria-cambio-de-guardas-o-cerraduras-tsx": () => import("./../../../src/pages/cerrajeria/cambio-de-guardas-o-cerraduras.tsx" /* webpackChunkName: "component---src-pages-cerrajeria-cambio-de-guardas-o-cerraduras-tsx" */),
  "component---src-pages-cerrajeria-programacion-de-llaves-con-chip-tsx": () => import("./../../../src/pages/cerrajeria/programacion-de-llaves-con-chip.tsx" /* webpackChunkName: "component---src-pages-cerrajeria-programacion-de-llaves-con-chip-tsx" */),
  "component---src-pages-cerrajeria-tsx": () => import("./../../../src/pages/cerrajeria.tsx" /* webpackChunkName: "component---src-pages-cerrajeria-tsx" */),
  "component---src-pages-electricistas-tsx": () => import("./../../../src/pages/electricistas.tsx" /* webpackChunkName: "component---src-pages-electricistas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plomeria-tsx": () => import("./../../../src/pages/plomeria.tsx" /* webpackChunkName: "component---src-pages-plomeria-tsx" */)
}

